$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");
  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  var menuItems = document.querySelectorAll("#menu li.has-submenu");
  Array.prototype.forEach.call(menuItems, function (el, i) {
    var activatingA = el.querySelector("a");
    var btn =
      '<button><span><span class="is-sr-only">Mostra el submenú per “' +
      activatingA.text +
      "”</span></span></button>";
    activatingA.insertAdjacentHTML("afterend", btn);
    // activatingA.setAttribute("aria-expanded", "false");

    el.querySelector("button").addEventListener("click", function (event) {
      var currentMenu = this.parentNode;
      Array.prototype.forEach.call(menuItems, function (el, i) {
        if (currentMenu !== el) {
          el.classList.remove("open");
          el.classList.add("close");
          //el.querySelector("a").setAttribute("aria-expanded", "false");
          el.querySelector("button").setAttribute("aria-expanded", "false");
        }
      });
      //console.log(this.parentNode.className);
      if (this.parentNode.classList.contains("close")) {
        this.parentNode.classList.add("open");
        this.parentNode.classList.remove("close");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "true");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "true");
      } else {
        this.parentNode.classList.add("close");
        this.parentNode.classList.remove("open");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "false");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "false");
      }
      event.preventDefault();
    });
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // ------
  // Slider
  // ------
  $(".slider").slick({
    dots: true,
  });

  // MAIN SLIDER
  $(".main-slider").slick({
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    dotsClass: "slick-dots column is-4 is-offset-8 pt-2",
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
        },
      },
    ],
  });

  //TOT CLICABLE
  $(".clic").on("click", function () {
    if (e.target.tagName === 'a') return; // En cas de fer clic a un vincle descartem
    window.location = $(this).find("a.link-dn").attr("href");
    return false;
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
  // {
  // offset: 0,
  // tolerance: 5,
  // classes: {
  //   initial: "animated",
  //   pinned: "slideDown",
  //   unpinned: "slideUp"
  // }
  // }
  // );

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }

  // DROPDOWNS
  // $(document).on('click', function() {
  //   $(".dropdown").removeClass('is-active');
  // });
  // $('.dropdown .dropdown-trigger').on('click', function(e) {
  //   e.stopPropagation();
  //   $(this).parent().toggleClass('is-active');
  // });
});

// Connected toggles (http://edenspiekermann.github.io/a11y-toggle/#connected-toggles)
function collapse(toggle) {
  var id = toggle.getAttribute("data-a11y-toggle");
  var collapsibleBox = document.getElementById(id);
  collapsibleBox.setAttribute("aria-hidden", true);
  toggle.setAttribute("aria-expanded", false);
}

function collapseAll(event) {
  toggles
    .filter(function (toggle) {
      return toggle !== event.target;
    })
    .forEach(collapse);
}

var toggles = Array.prototype.slice.call(
  document.querySelectorAll(".connected-toggles [data-a11y-toggle]")
);

toggles.forEach(function (toggle) {
  toggle.addEventListener("click", collapseAll);
});

//amagar toggles desplegats al clicar fora
$("body").click(function (e) {
  var allyAttribute = $(e.target).attr("data-a11y-toggle");
  if (
    (typeof allyAttribute === typeof undefined || allyAttribute === false) &&
    $(e.target).closest("#target-2").length == 0
  ) {
    $("#target-1, #target-2").attr("aria-hidden", "true");
  }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll(".collapsible__title");

  Array.prototype.forEach.call(headings, (heading) => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-content-center p-2">
        ${heading.textContent}

        <svg width="16px" height="11px" aria-hidden="true" viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="down" transform="translate(0.152786, 0.604600)" fill="#000000" fill-rule="nonzero">
            <path d="M7.75400702,9.92509508 L0.0405644006,0.298537705 C-0.0594618289,0.172642623 0.0396679088,0.000124590164 0.183879155,0.000124590164 L2.65686276,0.000124590164 C2.74510538,0.000124590164 2.78980374,0.0350885246 2.84513161,0.0904163934 L7.89726276,6.39533443 L12.9139841,0.13435082 C12.9910857,0.0406 13.0393677,2.84217094e-14 13.1368333,2.84217094e-14 L15.6104398,2.84217094e-14 C15.7880792,2.84217094e-14 15.836489,0.205429508 15.7537546,0.298413115 L8.04031194,9.92497049 C7.96795129,10.0159016 7.82527588,10.0143672 7.7538103,9.92497049 L7.75400702,9.92509508 Z" id="Path"></path>
        </g>
    </g>
</svg>
      </button>
    `;
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = [];
      while (
        elem.nextElementSibling &&
        elem.nextElementSibling.className !== "collapsible__title"
      ) {
        elems.push(elem.nextElementSibling);
        elem = elem.nextElementSibling;
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node);
      });

      return elems;
    };

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading);

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement("div");
    wrapper.hidden = true;

    // Add each element of `contents` to `wrapper`
    contents.forEach((node) => {
      wrapper.appendChild(node);
    });

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);

    // Assign the button
    let btn = heading.querySelector("button");

    btn.onclick = () => {
      //fer que les altres pestanyes es tanquin quan se'n clica una
      $('.collapsible').find("button").not(btn).attr("aria-expanded", false);
      $('.collapsible__title').next().attr('hidden','hidden');
      
      // Cast the state as a boolean
      let expanded = btn.getAttribute("aria-expanded") === "true" || false;

      // Switch the state
      btn.setAttribute("aria-expanded", !expanded);
      // Switch the content's visibility
      wrapper.hidden = expanded;
    };
  });

  if (
    $(".collapsible__title").length > 0 &&
    window.location.hash.indexOf("#entry-") > -1 &&
    $(window.location.hash).length > 0
  ) {
    $(window.location.hash).find("button").attr("aria-expanded", true);
    $(window.location.hash).next().show();
    $("html, body").animate(
      {
        scrollTop: $(window.location.hash).parent().offset().top,
      },
      1000
    );
  }
})();

// -------------------------
// Tabs per apartat Jornades
// -------------------------
$(".tabs-jornades").accordionortabs();
